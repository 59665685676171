body {
  padding: 0 !important;
  margin: 0 !important;
  height: calc(100vh - calc(100vh - 100%));
  font-family: Helvetica, sans-serif;
}

.page {
  width: 21cm;
  min-height: 29.7cm;
  padding: 2cm;
  margin: 2.5cm auto 2.5cm auto;
  border: 1px #D3D3D3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  position: relative;
}

@page {
  size: A4;
  margin-top: 2cm;
  margin-bottom: 2cm;
}

@media print {

  body {
    display: table;
    table-layout: fixed;
    margin-top: 0;
    margin-bottom: 0;
    height: auto;
    position: relative;
  }

  .no-print {
    display: none;
    overflow: hidden;
  }

  .page {
    box-shadow: none;
    border: 0;
    margin: 0;
    color: #000;
    background-color: #fff;
  }

  table {
    page-break-after: auto;
  }

  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }

  td {
    page-break-inside: avoid;
    page-break-after: auto;
  }

  thead {
    display: table-header-group;
  }

  tfoot {
    display: table-footer-group;
  }

  .sign {
    display: inline-block;
    width: 100%;
    page-break-inside: avoid;
  }
}

.sign {
  position: relative;
}

.sign-img {
  position: absolute;
}

@media only screen and (max-width: 768px) {
  .p-button.p-button-icon-only {
    padding: 0.5rem 1rem !important;
    width: auto;
  }
}
